<template>
  <div class="container">
    <div class="container content">
      <!--<v-alert
        text
        class="gepi-alert"
        color="cyan darken-2"
        icon="mdi-alert-circle"
      >
        The data in GEPI.io is currently incomplete. We will integrate
        additional data sources and build new GEPI.io features as the Centers
        continue to reimagine internal data standards and processes.
      </v-alert>
    -->
      <div class="box-container title-box">
        <div class="box-eigth">
          <div class="award-icon-box">
            <div class="award-icon"></div>
          </div>
        </div>
        <div class="box-half">
          <h1 class="name">{{ award.name }}</h1>
          <v-chip
            v-if="award.center === 'GovEx'"
            class="ma-2 tag-chip govex-chip"
            >GovEx</v-chip
          >
          <v-chip v-if="award.center === 'BCPI'" class="ma-2 tag-chip bcpi-chip"
            >BCPI</v-chip
          >
          <v-chip
            v-if="award.type && award.type[0] === 'ACCT'"
            class="ma-2 tag-chip type-acct"
            >Account</v-chip
          >
          <v-chip
            v-if="award.type && award.type[0] === 'GRNT'"
            class="ma-2 tag-chip type-grnt"
            >Grant</v-chip
          >
        </div>
      </div>
      <div>{{ award.description }}</div>

      <button
        class="tablink tablinkthird"
        v-on:click="setTab('engagements')"
        v-bind:class="{ active: this.activeTab === 'engagements' }"
      >
        Engagements
      </button>
      <button
        class="tablink tablinkthird"
        v-on:click="setTab('contacts')"
        v-bind:class="{ active: this.activeTab === 'contacts' }"
      >
        Contacts
      </button>
      <button
        class="tablink tablinkthird"
        v-on:click="setTab('files')"
        v-bind:class="{ active: this.activeTab === 'files' }"
      >
        Files
      </button>
      <div
        id="overview"
        class="tabcontent"
        v-show="this.activeTab === 'engagements'"
      >
        <timeline
          :filesEndPoint="`attachments?award=${this.$route.params.id}`"
          :projectsEndPoint="`projects?award=${this.$route.params.id}`"
          :updatesEndPoint="`projects/update?award=${this.$route.params.id}`"
        />

        <h2 class="pt-6">Associated Program Cohorts</h2>
        <program-card :endPoint="`programs?award=${this.$route.params.id}`">
        </program-card>
      </div>
      <div
        id="clients"
        class="tabcontent"
        v-show="this.activeTab === 'contacts'"
      >
        <partner-card :title="`Partners`" />
        <!-- <div>
          <h2 class="pb-8 pt-8">Key Contacts</h2>
          <keep-alive>
            <contacts-table
              :key="refreshTrigger"
              :endPoint="
                `contacts/people?ordering=name&grant_key_contact=${this.$route.params.id}`
              "
              :columns="['Name', 'Job Title', 'Organization', 'Project']"
              :projects="award.projects"
              :showDeleteContactsButton="true"
            />
          </keep-alive>
        </div>
        <h2 class="pt-8">All Contacts</h2>
        <keep-alive>
          <contacts-table
            @update-key-contacts="refresh"
            :columns="['Name', 'Job Title']"
            :endPoint="
              `contacts/people?ordering=name&award=${this.$route.params.id}`
            "
            class="mt-8"
            :projects="award.projects"
            :showAddContactsButton="true"
          ></contacts-table>
        </keep-alive> -->
      </div>
      <div id="files" class="tabcontent" v-show="this.activeTab === 'files'">
        <file-explorer
          :filesEndPoint="`attachments?award=${this.$route.params.id}`"
          :foldersEndPoint="`folders?award=${this.$route.params.id}`"
        />
      </div>
    </div>
  </div>
</template>
<style>
/* Page CSS */

h1 {
  padding-top: 5px;
}

.award-icon {
  height: 50px;
  width: 50px;
  margin: 0px auto;
  padding: 55px 10px 10px 10px;
  background-color: white;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/awards.svg");
}
.award-icon-box {
  margin: 15px 10px;
  border-radius: 10px;
  height: 70px;
  width: 70px;
  padding-top: 5px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua);
}

.panel-title.funder-panel {
  background-color: var(--very-light-grey);
  border-radius: 6px 6px 0px 0px;
  font-family: "roboto-bold";
  text-align: left;
}

.award-center {
  text-align: center;
}
.funder-panel {
  font-size: 1.1em;
}
.funding {
  padding-bottom: 5px;
}
.funding .detail {
  margin: 10px;
}
.testimonial-button {
  width: 80px;
}

/* Responsive formatting */
@media screen and (max-width: 1000px) {
  .award-icon-box {
    margin: 0 auto;
  }
}
</style>

<script>
// import PieChart_ProjectStatus from "../components/PieChart_ProjectStatus";
// import Testimonial_Quote from "../components/Testimonial_Quote";
// import ProgramsTable from "../components/ProgramsTable";
import ProgramCard from "../components/ProgramCard.vue";
import Timeline from "../components/Timeline.vue";
import PartnerCard from "../components/PartnerCard.vue";
import ContactsTable from "../components/ContactsTable.vue";
import FileExplorer from "../components/FileExplorer.vue";
import refreshTriggerMixin from "../mixins/refreshTrigger";
export default {
  mixins: [refreshTriggerMixin],
  components: {
    ProgramCard,
    Timeline,
    PartnerCard,
    // ContactsTable,
    FileExplorer,
  },
  data() {
    return {
      award: {},
      activeTab: "engagements",
      contacts: [],
      keyContacts: [],
    };
  },
  created() {
    this.getAward();
  },
  computed: {
    programCount() {
      return this.programs.length;
    },
    // newProjects: function() {
    //   let newProjectCount = 0;
    //   // Get current unix timestamp
    //   let currentTime = Math.round(+new Date() / 1000);
    //   let projectTime = 0;

    //   //Looping through all projects associated with all programs under this award
    //   for (var j = 0; j < this.award.programs.length; j++) {
    //     for (
    //       var i = 0;
    //       i < this.award.programs[j].program_projects.length;
    //       i++
    //     ) {
    //       // Get time each testimonial was made
    //       if (this.award.programs[0].program_projects[i]) {
    //         projectTime = Math.floor(
    //           new Date(
    //             this.award.programs[0].program_projects[i].created_date
    //           ).getTime() / 1000
    //         );
    //         // Check the times against each other, increment count if it is less then 1 week (604800 seconds)
    //         if (currentTime - projectTime < 604800) {
    //           newProjectCount++;
    //         }
    //       }
    //     }
    //   }

    //   return newProjectCount;
    // },
    // newTestimonials: function() {
    //   let newTestimonialCount = 0;
    //   // Get current unix timestamp
    //   let currentTime = Math.round(+new Date() / 1000);
    //   let testimonialTime = 0;

    //   for (var i = 0; i < this.award.testimonials.length; i++) {
    //     // Get time each testimonial was made
    //     testimonialTime = Math.floor(
    //       new Date(this.award.testimonials[i].created_date).getTime() / 1000
    //     );
    //     // Check the times against each other, increment count if it is less then 1 week (604800 seconds)
    //     if (currentTime - testimonialTime < 604800) {
    //       newTestimonialCount++;
    //     }
    //   }

    //   return newTestimonialCount;
    // },
    projectStatuses: function() {
      const counts = {};
      const statuses = [];
      this.programs.map((p) => {
        counts[p.status[1]] = (counts[p.status[1]] || 0) + 1;
      });

      statuses.push(
        ["Status", "Projects"],
        ["On Track", counts["On Track"]],
        ["At Risk", counts["At Risk"]],
        ["Off Track", counts["Off Track"]]
      );
      return statuses;
    },
  },
  methods: {
    getAward: function() {
      this.$api
        .get("awards/" + this.$route.params.id + "/")
        .then((response) => {
          this.award = response.data;
          this.setPageTitle(this.award.name);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.push({ name: "404" });
          }
        });
    },
    setTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
