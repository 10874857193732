<template>
  <div>
    <div class="results-count">
      Showing <strong>{{ resultCount }}</strong> Program<span
        v-if="resultCount != 1"
        >s</span
      >
    </div>
    <div id="projects-container">
      <v-card
        class="content-panel box-third project-panel"
        style="display: inline-block;"
        v-for="program in programs"
        :key="program.id"
      >
        <a class="no-underline" :href="`/programs/${program.id}`">
          <v-card-title
            class="panel-title justify-center text-h6 link-card-title white--text"
            :style="{ backgroundColor: getHeaderBackgroundColor(program) }"
            style="word-break: break-word;"
          >
            {{ program.name }}
          </v-card-title>
          <v-card-text class="grey--text pt-4 font-weight-medium">
            <p class="text-body-1 card-text-scrollable">
              {{ program.description }}
            </p>
          </v-card-text>
        </a>
      </v-card>
    </div>
  </div>
</template>
<style>
.input-box {
  width: 90%;
}
label[for="search"] {
  height: 25px;
}
#search {
  position: relative;
  top: -6px;
}
.content-panel.v-card .v-card__text p.text-body-1 {
  font-size: 13px !important;
  line-height: 18px;
}
</style>
<script>
export default {
  props: {
    endPoint: {
      type: String,
      required: false,
      default: "programs?",
    },
  },
  data() {
    return {
      programs: [],
    };
  },
  created() {
    this.getPrograms();
  },
  computed: {
    resultCount() {
      return this.programs.length;
    },
  },
  methods: {
    async getPrograms() {
      let url = this.endPoint;
      while (url) {
        let response = await this.$api.get(url);
        await this.programs.push.apply(this.programs, response.data.results);
        url = response.data.next;
      }
    },

    getHeaderBackgroundColor(program) {
      const { award } = program;
      const completionDateExists = award.length > 0 && award[0].completion_date;

      return completionDateExists ? "var(--dark-grey)" : "var(--green)";
    },
  },
};
</script>
