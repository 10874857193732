<template>
  <div v-if="resultCount > 0">
    <h2 class="pt-8">{{ title }}</h2>
    <div class="results-count">
      Showing <strong>{{ resultCount }}</strong> Partner<span
        v-if="resultCount != 1"
        >s</span
      >
    </div>
    <div class="gepi-items-container">
      <v-card
        class="content-panel box-third project-panel box-org partner-card"
        style="display: inline-block;"
        v-for="partner in sortedPartners"
        :key="partner.id"
      >
        <a class="no-underline" :href="`/contacts/organizations/${partner.id}`">
          <v-card-title
            class="panel-title justify-center text-h6 link-card-title white--text"
            color="#FFFFFF"
          >
            <div class="d-inline-flex flex-column">
              <span class="mb-n2">{{ partner.name }}</span>
              <span
                v-if="
                  partner.grant_relationship &&
                    partner.grant_relationship === 'FUND'
                "
                class="text-overline d-flex align-center"
                ><v-icon color="yellow" x-small class="mr-1">mdi-star</v-icon
                >FUNDER</span
              >
            </div>
          </v-card-title>
          <v-card-text class="grey--text pt-4 font-weight-medium">
            {{ partner.description }}
            <v-card-text class="pt-5 mb-n6">
              <v-chip
                small
                color="var(--very-light-grey)"
                class="font-weight-medium ml-n4"
                text-color="var(--very-dark-grey)"
                v-if="partner.type"
              >
                {{ partner.type[1] }}
              </v-chip>
            </v-card-text>
          </v-card-text>
        </a>
      </v-card>
    </div>
  </div>
</template>
<style>
.input-box {
  width: 90%;
}
label[for="search"] {
  height: 25px;
}
#search {
  position: relative;
  top: -6px;
}
.link-card-title {
  word-break: break-word;
  background-color: var(--dark-grey);
}
.box-org {
  display: inline-block;
  width: 48%;
}
</style>
<script>
export default {
  props: {
    endPoint: {
      type: String,
      required: false,
      default: "contacts/organizations?",
    },
    title: {
      type: String,
      required: false,
      default: "Partners",
    },
  },
  components: {},
  data() {
    return {
      partners: {},
    };
  },
  created() {
    this.getPartners();
  },

  computed: {
    resultCount() {
      return this.partners.count;
    },
    sortedPartners() {
      return (this.partners?.results ?? [])
        .slice()
        .sort((org1, org2) =>
          org1.grant_relationship === "FUND" &&
          org2.grant_relationship !== "FUND"
            ? -1
            : org1.grant_relationship !== "FUND" &&
              org2.grant_relationship === "FUND"
            ? 1
            : org1.name.localeCompare(org2.name)
        );
    },
  },
  methods: {
    getPartners() {
      let url;
      if (this.$route.name === "grant") {
        url = `${this.endPoint}&grant_relationship=${this.$route.params.id}`;
      } else {
        url = `${this.endPoint}&project_relationship=${this.$route.params.id}`;
      }
      this.$api.get(url).then((response) => {
        this.partners = response.data;
      });
    },
  },
};
</script>
